import React, { useState, useContext } from 'react';
import * as S from './AddToCartButtonV2.styles';
import { addToCart } from '../../utils/addToCart';
import FunnelContext from '../../context/FunnelContext';
import { Builder } from '@builder.io/react';

const AddToCartButton = props => {
  const { buttonText, backgroundColor, backgroundColorHover } = props;
  const context = useContext(FunnelContext);

  const {
    upsell: upsellUrl,
    currency,
    onetime,
    subscription,
    currentCategory,
    currentProduct,
    path,
    funnelUrl,
    pageDesign,
    bumpOfferChecked,
    bumpOfferIndex,
    frequency,
    setSubmittedToCart,
    extraObjects
  } = context;

  const [buttonClicked, setButtonClicked] = useState(false);

  const triggerAddToCart = () => {
    if (Builder.isEditing) {
      return false;
    }

    if (buttonClicked) {
      return false;
    }

    const extraData = {
      ...extraObjects,
      currency_code: currency.code,
      currency_symbol: currency.symbol,
      upsell_url: upsellUrl,
      setButtonDisabled: setButtonClicked,
      setButtonClicked,
      setSubmittedToCart
    };

    setButtonClicked(true);
    setSubmittedToCart(true);

    const productPrefix =
      currentCategory === `onetime` ? `onetime` : `subscription`;

    const { discounts, products, bumpoffers } =
      currentCategory === 'subscriptions' ? subscription : onetime;
    const bumpOfferData = bumpOfferChecked ? bumpoffers[bumpOfferIndex] : {};
    const product = products.find(
      (_, index) => `${productPrefix}_${index}` === currentProduct
    );
    product.schedule = currentCategory === 'subscriptions' ? frequency : '';
    addToCart(product, discounts, extraData, bumpOfferData, path, funnelUrl);
  };

  const AddToCartButton = ({ children }) => {
    return (
      <S.AddToCartButton
        type="button"
        onClick={triggerAddToCart}
        disabled={buttonClicked ? 'disabled' : ''}
        className={buttonClicked ? 'processing' : ''}
        pageDesign={pageDesign}
        backgroundColor={backgroundColor}
        backgroundColorHover={backgroundColorHover}
      >
        <span>{children}</span>
      </S.AddToCartButton>
    );
  };

  return <AddToCartButton>{buttonText}</AddToCartButton>;
};

export default AddToCartButton;
